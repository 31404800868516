<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування сервіса" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <!-- <va-card class="mb-3" title="Image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="imgExisting = deleteImage(imgId) ? '' : imgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    @input="delete errors.image"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.image" style="color: red;">{{ this.errors.image[0] }}</div>
                  <va-button @click="uploadImage('image', 'ServiceImage', 'Service[image]', img[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Label *"
                  v-model="label"
                  :messages="['The recommended number of characters is 40']"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="Content *"
                  v-model="content"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 255']"
                  @input="delete errors.content"
                  :error="!!errors.content"
                  :error-messages="errors.content"
                />
                <va-toggle label="Published" small v-model="published"/>
                <va-input
                  label="Position"
                  v-model.number="position"
                  @input="delete errors.position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                /> -->
                <va-input
                  label="title"
                  v-model="title"
                  @input="delete errors.title"
                  :error="!!errors.title"
                  :error-messages="errors.title"
                />
                <!-- <va-input
                  label="description"
                  v-model="description"
                  @input="delete errors.description"
                  :error="!!errors.description"
                  :error-messages="errors.description"
                /> -->
                <!-- <va-input
                  label="text"
                  v-model="text"
                  @input="delete errors.text"
                  :error="!!errors.text"
                  :error-messages="errors.text"
                /> -->
                <div class="mb-3">
                  <span>text</span>
                  <ckeditor v-model="text" :editor="editor" :config="editorConfig"></ckeditor>
                </div>

                <!-- <div class="mb-3">
                  <span>title</span>
                  <ckeditor v-model="title" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <div class="mb-3">
                  <span>description</span>
                  <ckeditor v-model="description" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                -->
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      img: [],
      imgExisting: '',
      imgId: '',
      label: '',
      position: '',
      content: '',
      published: false,

      sign: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
      // description: '',
      title: '',
      text: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AGRO_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (attr, entityAttribute, param, file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', 'backend\\modules\\service\\models\\Service')
      formData.append('attribute', attr)
      formData.append('entity_attribute', entityAttribute)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (this.$attrs.id) {
        formData.append('id', this.$attrs.id)
      } else {
        formData.append('sign', this.sign)
      }

      axios.post(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/admin/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/admin/upload/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      // const data = {
      //   label: this.label,
      //   position: this.position,
      //   published: !!this.published,
      //   content: this.content,
      //   sign: this.sign ? this.sign : null,
      // }
      const data = {
        main: {
          // description: this.description,
          published: !!this.published,
          text: this.text,
          title: this.title,
        },
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/value/${this.$attrs.id}`, data)
          .then(response => {
            console.log(data)
            this.$router.push({ name: 'prylukyagro-widgets-values' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/value`, data)
          .then(response => {
            this.$router.push({ name: 'prylukyagro-widgets-values' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/value/${this.$attrs.id}`)
          .then(response => {
            // console.log(response.data.main)
            this.published = !!response.data.main.published
            this.text = response.data.main.text
            this.title = response.data.main.title
            // this.content = response.data.content
            // this.imgExisting = process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.image.originalSrc
            // this.imgId = response.data.imageId
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
